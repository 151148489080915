/* import __COLOCATED_TEMPLATE__ from './channel-filter.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { get } from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';
import {
  AVAILABLE_CHANNEL_MAPPINGS,
  REPORTING_FILTER_SELECT_ALL,
  STANDALONE_AVAILABLE_CHANNEL_MAPPINGS,
} from 'embercom/lib/reporting/flexible/constants';

export default class ChannelFilter extends Component {
  @service appService;
  @service intercomEventService;
  @tracked label = this.formattedLabel;
  @tracked availableChannels = [];
  selectedDropdownItems = this.args.selected;

  constructor() {
    super(...arguments);
    this.loadKnownValues.perform();
  }

  get allItems() {
    let channelMappings = this.appService.app.canUseStandalone
      ? STANDALONE_AVAILABLE_CHANNEL_MAPPINGS
      : AVAILABLE_CHANNEL_MAPPINGS;
    return this.availableChannels
      .map((channelName) => ({
        text: channelMappings[channelName] || channelName,
        value: channelName,
        isSelected: this.args.selected.includes(channelName),
      }))
      .sort((channel1, channel2) => channel1.text.localeCompare(channel2.text));
  }

  get selectedChannels() {
    return this.selectedDropdownItems.filter((item) => item !== REPORTING_FILTER_SELECT_ALL);
  }

  get items() {
    return this.groupList;
  }

  get groupList() {
    return [
      {
        items: this.allItems,
      },
    ];
  }

  get disabled() {
    return this.availableChannels && this.availableChannels.length === 0;
  }

  get formattedLabel() {
    if (this.selectedChannels.length === 0) {
      return 'is Any';
    }
    let channelMappings = this.appService.app.canUseStandalone
      ? STANDALONE_AVAILABLE_CHANNEL_MAPPINGS
      : AVAILABLE_CHANNEL_MAPPINGS;
    let channels = this.selectedChannels.map((channel) => {
      return channelMappings[channel] || channel;
    });
    return `is ${channels.join(', ')}`;
  }

  @task
  *loadKnownValues() {
    this.availableChannels = yield get('/ember/reporting/known_values', {
      app_id: this.appService.app.id,
      source: 'conversation',
      property: 'channel_type',
    });
  }

  @action
  onClose() {
    if (!this.selectedDropdownItems.length) {
      this.selectedDropdownItems = [REPORTING_FILTER_SELECT_ALL];
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_channel',
      object: this.args.analyticsObject,
      channels: this.selectedChannels,
    });

    this.args.setSelected(this.selectedDropdownItems);
    this.label = this.formattedLabel;
  }

  @action
  removeFilter() {
    this.selectedDropdownItems = [];
    this.args.removeFilter();
  }
}
