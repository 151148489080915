/* RESPONSIBLE TEAM: team-ai-insights */
/* ToDo: Localize these strings when the strings are finalized */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { type LogicalFilter, type Filter } from 'embercom/components/reporting/custom/filters';
import {
  and,
  not,
  FinInsightsSankeyConfig,
} from 'embercom/components/reporting/automation/ai-insights/fin-insights/sankey-config';
import { type Aggregation } from 'embercom/objects/reporting/unified/metrics/types';
import type IntlService from 'ember-intl/services/intl';

const groupings = {
  queryType: {
    informational: FinInsightsSankeyConfig.filters.queryTypeInformational,
    personalized: FinInsightsSankeyConfig.filters.queryTypePersonalized,
    taskBased: FinInsightsSankeyConfig.filters.queryTypeActions,
    advanced: FinInsightsSankeyConfig.filters.queryTypeComplex,
    // TODO: we don't have an agreed definition for "nonSupport" yet,
  },
  handled: {
    resolvedByFin: FinInsightsSankeyConfig.filters.finOnlyResolved,
    routedToTeammate: FinInsightsSankeyConfig.filters.finAndTeammate,
    handledFullyByTeammate: and(
      not(FinInsightsSankeyConfig.filters.finAnswered),
      FinInsightsSankeyConfig.filters.teammateWasEverInvolved,
    ),
  },
  csat: {
    positive: FinInsightsSankeyConfig.filters.positiveCSAT,
    negative: FinInsightsSankeyConfig.filters.negativeCSAT,
    neutral: FinInsightsSankeyConfig.filters.neutralCSAT,
  },
};

export interface ChartSeriesDefinition {
  metric_id: string;
  aggregation?: Aggregation;
  label?: string;
  tooltip?: string;
  filters?: LogicalFilter | Filter;
}

function insightsMetrics(intl: IntlService): Record<string, ChartSeriesDefinition> {
  return {
    volume: {
      metric_id: 'v1.new_conversations',
      aggregation: 'count',
      label: intl.t('components.reporting.automation.fin-insights.new-conversations'),
    },
    resolutionRate: {
      metric_id: 'fin.resolution_rate',
    },
    handlingTimeSum: {
      metric_id: 'v1.handling_time',
      aggregation: 'sum',
      label: intl.t('components.reporting.automation.fin-insights.handling-time-sum'),
    },
    handlingTimeMedian: {
      metric_id: 'v1.handling_time',
      aggregation: 'median',
      label: intl.t('components.reporting.automation.fin-insights.handling-time-median'),
    },
    aiCsatScore: {
      metric_id: 'conversation.ai_generated_metrics.csat_score',
      label: intl.t('components.reporting.automation.fin-insights.ai-csat-score'),
      tooltip: intl.t('components.reporting.automation.fin-insights.ai-csat-score-tooltip'),
    },
    surveyedCsatScore: {
      metric_id: 'conversation_rating.any_agent.csat',
      label: intl.t('components.reporting.automation.fin-insights.surveyed-csat-score'),
      tooltip: intl.t('components.reporting.automation.fin-insights.surveyed-csat-score-tooltip'),
    },
    negativelyRatedAiCsat: {
      metric_id: 'conversation.ai_generated_metrics.csat.negatively_rated.count',
      label: intl.t('components.reporting.automation.fin-insights.negatively-rated-ai-csat'),
      tooltip: intl.t(
        'components.reporting.automation.fin-insights.negatively-rated-ai-csat-tooltip',
      ),
      // TODO: check that we want this
      filters: FinInsightsSankeyConfig.filters.negativeCSAT,
    },
    positivelyRatedAiCsat: {
      metric_id: 'conversation.ai_generated_metrics.csat.positively_rated.count',
      label: intl.t('components.reporting.automation.fin-insights.positively-rated-ai-csat'),
      tooltip: intl.t(
        'components.reporting.automation.fin-insights.positively-rated-ai-csat-tooltip',
      ),
    },
  } as const;
}

function jobDefinitions(intl: IntlService) {
  let metrics = insightsMetrics(intl);

  return [
    {
      id: 'identifyFinContentGaps',
      label: intl.t('components.reporting.automation.fin-insights.jobs.content-gaps.label'),
      table: {
        cta: 'components.reporting.automation.fin-insights.jobs.content-gaps.table-cta',
        filters: and(groupings.queryType.informational, groupings.handled.routedToTeammate),
        chartSeries: [
          metrics.volume,
          metrics.handlingTimeSum,
          metrics.handlingTimeMedian,
          metrics.aiCsatScore,
        ],
        highlightedMetricIndex: 1, // total handling time
      },
      sankey: {
        type: 'queryTypeToRes',
        highlightedLinks: 'infoToRoutedToTeammate',
      },
      topLine: {
        chartSeries: [metrics.volume],
        filters: and(groupings.queryType.informational, groupings.handled.routedToTeammate),
        label: intl.t(
          'components.reporting.automation.fin-insights.jobs.content-gaps.top-line.label',
        ),
        description: intl.t(
          'components.reporting.automation.fin-insights.jobs.content-gaps.top-line.description',
        ),
        showSuggestions: true,
      },
    },
    {
      id: 'identifyFinDataGaps',
      label: intl.t('components.reporting.automation.fin-insights.jobs.data-gaps.label'),
      table: {
        cta: 'components.reporting.automation.fin-insights.jobs.data-gaps.table-cta',
        filters: and(groupings.queryType.personalized, groupings.handled.routedToTeammate),
        chartSeries: [
          metrics.volume,
          metrics.handlingTimeSum,
          metrics.handlingTimeMedian,
          metrics.aiCsatScore,
        ],
        highlightedMetricIndex: 1, // total handling time
      },
      sankey: {
        type: 'queryTypeToRes',
        highlightedLinks: 'personalizedToRoutedToTeam',
      },
      topLine: {
        chartSeries: [metrics.volume],
        filters: and(groupings.queryType.personalized, groupings.handled.routedToTeammate),
        label: intl.t('components.reporting.automation.fin-insights.jobs.data-gaps.top-line.label'),
        description: intl.t(
          'components.reporting.automation.fin-insights.jobs.data-gaps.top-line.description',
        ),
        showSuggestions: false,
      },
    },
    {
      id: 'identifyFinTasks',
      label: intl.t('components.reporting.automation.fin-insights.jobs.fin-tasks.label'),
      table: {
        cta: 'components.reporting.automation.fin-insights.jobs.fin-tasks.table-cta',
        filters: and(groupings.queryType.taskBased, groupings.handled.routedToTeammate),
        chartSeries: [
          metrics.volume,
          metrics.handlingTimeSum,
          metrics.handlingTimeMedian,
          metrics.aiCsatScore,
        ],
        highlightedMetricIndex: 1, // total handling time
      },
      sankey: {
        type: 'queryTypeToRes',
        highlightedLinks: 'taskBasedToRoutedToTeam',
      },
      topLine: {
        chartSeries: [metrics.volume],
        filters: and(groupings.queryType.taskBased, groupings.handled.routedToTeammate),
        label: intl.t('components.reporting.automation.fin-insights.jobs.fin-tasks.top-line.label'),
        description: intl.t(
          'components.reporting.automation.fin-insights.jobs.fin-tasks.top-line.description',
        ),
        showSuggestions: false,
      },
    },
    {
      id: 'reviewLowQualityFinResolutions',
      label: intl.t(
        'components.reporting.automation.fin-insights.jobs.low-quality-fin-resolutions.label',
      ),
      table: {
        cta: 'components.reporting.automation.fin-insights.jobs.low-quality-fin-resolutions.table-cta',
        filters: and(groupings.handled.resolvedByFin, groupings.csat.negative),
        chartSeries: [
          metrics.volume,
          metrics.negativelyRatedAiCsat,
          metrics.handlingTimeMedian,
          metrics.aiCsatScore,
        ],
        highlightedMetricIndex: 1, // negative AI CSAT ratings
      },
      sankey: {
        type: 'resToCSAT',
        highlightedLinks: 'resolvedByFinToNegativeCSAT',
      },
      topLine: {
        chartSeries: [metrics.volume],
        filters: and(groupings.handled.resolvedByFin, groupings.csat.negative),
        label: intl.t(
          'components.reporting.automation.fin-insights.jobs.low-quality-fin-resolutions.top-line.label',
        ),
        description: intl.t(
          'components.reporting.automation.fin-insights.jobs.low-quality-fin-resolutions.top-line.description',
        ),
        showSuggestions: false,
      },
    },
    {
      id: 'validateFinsResolutions',
      label: intl.t(
        'components.reporting.automation.fin-insights.jobs.validate-fins-resolutions.label',
      ),
      table: {
        cta: 'components.reporting.automation.fin-insights.jobs.validate-fins-resolutions.table-cta',
        filters: and(groupings.handled.resolvedByFin, groupings.csat.positive),
        chartSeries: [
          metrics.volume,
          metrics.positivelyRatedAiCsat,
          metrics.handlingTimeMedian,
          metrics.aiCsatScore,
          metrics.surveyedCsatScore,
        ],
        highlightedMetricIndex: 1, // positive AI CSAT ratings
      },
      sankey: {
        type: 'resToCSAT',
        highlightedLinks: 'resolvedByFinToPositiveCSAT',
      },
      topLine: {
        chartSeries: [metrics.volume],
        filters: and(groupings.handled.resolvedByFin, groupings.csat.positive),
        label: intl.t(
          'components.reporting.automation.fin-insights.jobs.validate-fins-resolutions.top-line.label',
        ),
        description: intl.t(
          'components.reporting.automation.fin-insights.jobs.validate-fins-resolutions.top-line.description',
        ),
        showSuggestions: false,
      },
    },
    {
      id: 'identifyFinInvolvementOpportunities',
      label: intl.t(
        'components.reporting.automation.fin-insights.jobs.involvement-opportunities.label',
      ),
      table: {
        cta: 'components.reporting.automation.fin-insights.jobs.involvement-opportunities.table-cta',
        filters: and(groupings.queryType.informational, groupings.handled.handledFullyByTeammate),
        chartSeries: [
          metrics.volume,
          metrics.handlingTimeSum,
          metrics.handlingTimeMedian,
          metrics.aiCsatScore,
        ],
        highlightedMetricIndex: 1, // total handling time
      },
      sankey: {
        type: 'queryTypeToRes',
        highlightedLinks: 'infoToTeammate',
      },
      topLine: {
        chartSeries: [metrics.volume],
        filters: and(groupings.queryType.informational, groupings.handled.handledFullyByTeammate),
        label: intl.t(
          'components.reporting.automation.fin-insights.jobs.involvement-opportunities.top-line.label',
        ),
        description: intl.t(
          'components.reporting.automation.fin-insights.jobs.involvement-opportunities.top-line.description',
        ),
        showSuggestions: true,
      },
    },
  ] as const;
}

type JobId = ReturnType<typeof jobDefinitions>[number]['id'];
type TranslationKey = string;

interface Job {
  id: JobId;
  label: string;
  table: {
    cta: TranslationKey;
    filters: LogicalFilter;
    chartSeries: readonly ChartSeriesDefinition[];
    highlightedMetricIndex: number;
  };
  sankey: {
    type: 'resToCSAT' | 'queryTypeToRes';
    highlightedLinks: string;
  };
  topLine: {
    chartSeries: readonly ChartSeriesDefinition[];
    filters: LogicalFilter;
    label: string;
    description: string;
    showSuggestions: boolean;
  };
}

const jobGroups: Record<string, readonly JobId[]> = {
  improveAnswers: ['identifyFinContentGaps', 'identifyFinDataGaps', 'identifyFinTasks'],
  checkQuality: ['reviewLowQualityFinResolutions', 'validateFinsResolutions'],
  increaseCoverage: ['identifyFinInvolvementOpportunities'],
} as const;

function jobs(intl: IntlService) {
  return jobDefinitions(intl) as readonly Job[];
}

export { jobGroups, jobs, type JobId, type Job };
