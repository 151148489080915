/* import __COLOCATED_TEMPLATE__ from './sankey.hbs'; */
/* RESPONSIBLE TEAM: team-ai-insights */
import Component from '@glimmer/component';
import type { Options, SeriesOptionsType, SeriesSankeyOptions } from 'highcharts';
import { use } from 'ember-resources/util/function-resource';
import { FinInsightsSankeyConfig } from 'embercom/components/reporting/automation/ai-insights/fin-insights/sankey-config';
import PALETTE from '@intercom/pulse/lib/palette';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { staticDataResource } from 'embercom/lib/reporting/static-data-resource';
import type { ReportState } from 'embercom/components/reporting/custom/report/chart-card';
import type { Job } from 'embercom/components/reporting/automation/ai-insights/fin-insights/jobs';

type ExtendedSeriesSankeyNodesOptionsObject =
  | Highcharts.SeriesSankeyNodesOptionsObject
  | { selected?: boolean };
type ExtendedSeriesSankeyOptions =
  | SeriesSankeyOptions
  | { nodes: Array<ExtendedSeriesSankeyNodesOptionsObject> };

export const SANKEY_COLORS = {
  queryType: PALETTE['vis-violet-30'],
  agentOutcome: PALETTE['vis-pink-30'],
  csat: PALETTE['vis-salmon-50'],
  default: PALETTE['neutral-border-emphasis'],
};

const FILTER_STATE_TO_SELECTED_NODES: Record<string, string[]> = {
  infoToRoutedToTeammate: ['queryTypeInformational', 'finRoutedToTeam'],
  infoToTeammate: ['queryTypeInformational', 'teammateOnly'],
  personalizedToRoutedToTeam: ['queryTypePersonalized', 'finRoutedToTeam'],
  taskBasedToRoutedToTeam: ['queryTypeActions', 'finRoutedToTeam'],
  resolvedByFinToPositiveCSAT: ['finResolved', 'positiveCSAT'],
  resolvedByFinToNegativeCSAT: ['finResolved', 'negativeCSAT'],
};

type OptionsWithSankey = Options & { plotOptions: { sankey: { borderRadius: number } } };

function safelyAdjustLayout(series: any, adjust: (series: any) => void) {
  if (!series.adjustingLayout) {
    series.adjustingLayout = true;
    // because layout adjustments can trigger a redraw, we need to prevent infinite recursion
    adjust(series);
    series.redraw();
    series.adjustingLayout = false;
  }
}

function adjustNodeLayout(event: Highcharts.PointerEventObject) {
  let chart = event.target as any;
  let series = chart.series[0];

  safelyAdjustLayout(series, () => {
    // verticallyAlignTeammateAndClosedNodes(series);
    // it's important to adjust the padding last because the other changes
    // can influence the data label positions
    // adjustPaddingIfDataLabelsOverlap(series);
  });
}

interface Signature {
  reportState: ReportState;
  selectedJob: Job;
}

export default class Sankey extends Component<Signature> {
  @service declare intl: IntlService;

  @use dataResource = staticDataResource(this, {
    serializedChart: () => this.sankeyChartDefinition,
    reportState: () => this.args.reportState,
  });

  get highchartsSeries() {
    return [this.sankeySeries] as SeriesOptionsType[];
  }

  get sankeyChartDefinition() {
    return {
      chart_series: FinInsightsSankeyConfig.getChartSeriesWithLabels(this.intl),
      view_by: null,
    };
  }

  highchartsOptions: OptionsWithSankey = {
    // a sankey chart doesn't have axes, or a legend
    // we are adding some dummy series so we can show stuff in the chart legend
    // but having other series will enable the axes, so we need to hide them
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    tooltip: {
      enabled: false,
      style: {
        zIndex: 100, // Higher than dataLabels zIndex
      },
      useHTML: true,
      backgroundColor: undefined,
      shadow: false,
      borderWidth: 0,
      borderColor: undefined,
      borderRadius: 0,
      padding: 0,
    },
    chart: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
      animation: false, // disables animations on redraw (but not initial draw)
      events: {
        redraw: adjustNodeLayout.bind(this),
      },
    },
    plotOptions: {
      sankey: {
        nodeWidth: 17,
        borderRadius: 4,
        nodePadding: 8,
        nodeAlignment: 'center',
        linkOpacity: 1.0,
        states: {
          inactive: {
            // this is really for the node data labels. The opacity for the links and nodes cannot be set separately
            // here, but they can be controlled via the styles defined in the hbs file
            opacity: 1,
          },
          select: {
            color: SANKEY_COLORS.default,
          },
        },
        dataLabels: {
          padding: 0,
          color: 'black',
          useHTML: true,
          // crop: false & overflow: allow tell highcharts that it's okay to render the labels in the margins
          crop: false,
          overflow: 'allow',
          allowOverlap: false, // we detect if labels are hidden and increase padding
          enabled: true,
          zIndex: 10,
          // see https://www.highcharts.com/docs/chart-concepts/templating
          nodeFormat: `
            <div class="text-default text-caption">
              <div class="font-semibold leading-4">
                {point.title}
                <span class="font-mono text-muted ml-2">
                  {point.sum:,.0f}
                </span>
              </div>
            </div>
          `,
        },
        levels: [
          {
            level: 0,
            dataLabels: {
              x: 30,
              align: 'left',
            },
          },
          {
            level: 1,
            dataLabels: {
              x: -30,
              align: 'right',
            },
          },
        ],
      },
    },
  };

  get metrics() {
    return FinInsightsSankeyConfig.getMetricsFromChartDataRequest(this.dataResource.chartData);
  }

  get hasData() {
    let topOfFunnel =
      this.metrics.queryTypeInformational ||
      this.metrics.queryTypePersonalized ||
      this.metrics.queryTypeComplex ||
      this.metrics.queryTypeActions;
    return !!topOfFunnel;
  }

  get queryTypeNodes() {
    return [
      {
        id: 'queryTypeInformational',
        title: this.intl.t(
          'components.reporting.automation.ai-insights.fin-insights.sankey.nodes.informational',
        ),
        color: SANKEY_COLORS.queryType,
        selected: this.nodeSelected('queryTypeInformational'),
      },
      {
        id: 'queryTypePersonalized',
        title: this.intl.t(
          'components.reporting.automation.ai-insights.fin-insights.sankey.nodes.personalized',
        ),
        color: SANKEY_COLORS.queryType,
        selected: this.nodeSelected('queryTypePersonalized'),
      },
      {
        id: 'queryTypeActions',
        title: this.intl.t(
          'components.reporting.automation.ai-insights.fin-insights.sankey.nodes.actions',
        ),
        color: SANKEY_COLORS.queryType,
        selected: this.nodeSelected('queryTypeActions'),
      },
      {
        id: 'queryTypeComplex',
        title: this.intl.t(
          'components.reporting.automation.ai-insights.fin-insights.sankey.nodes.complex',
        ),
        color: SANKEY_COLORS.queryType,
        selected: this.nodeSelected('queryTypeComplex'),
      },
    ];
  }

  get handlingNodes() {
    return [
      {
        id: 'finResolved',
        title: this.intl.t(
          'components.reporting.automation.ai-insights.fin-insights.sankey.nodes.finResolved',
        ),
        color: SANKEY_COLORS.agentOutcome,
        selected: this.nodeSelected('finResolved'),
      },
      {
        id: 'finRoutedToTeam',
        title: this.intl.t(
          'components.reporting.automation.ai-insights.fin-insights.sankey.nodes.finRoutedToTeam',
        ),
        color: SANKEY_COLORS.agentOutcome,
        selected: this.nodeSelected('finRoutedToTeam'),
      },
      {
        id: 'teammateOnly',
        title: this.intl.t(
          'components.reporting.automation.ai-insights.fin-insights.sankey.nodes.teammateOnly',
        ),
        color: SANKEY_COLORS.agentOutcome,
        selected: this.nodeSelected('teammateOnly'),
      },
    ];
  }

  get csatNodes() {
    return [
      {
        id: 'positiveCSAT',
        title: this.intl.t(
          'components.reporting.automation.ai-insights.fin-insights.sankey.nodes.positiveCSAT',
        ),
        color: SANKEY_COLORS.csat,
        selected: this.nodeSelected('positiveCSAT'),
      },
      {
        id: 'neutralCSAT',
        title: this.intl.t(
          'components.reporting.automation.ai-insights.fin-insights.sankey.nodes.neutralCSAT',
        ),
        color: SANKEY_COLORS.csat,
        selected: this.nodeSelected('neutralCSAT'),
      },
      {
        id: 'negativeCSAT',
        title: this.intl.t(
          'components.reporting.automation.ai-insights.fin-insights.sankey.nodes.negativeCSAT',
        ),
        color: SANKEY_COLORS.csat,
        selected: this.nodeSelected('negativeCSAT'),
      },
    ];
  }

  get queryTypeToHandlingNodes() {
    return [
      {
        from: 'queryTypeInformational',
        to: 'finResolved',
        weight: this.metrics.informationalAndFinResolved,
        selected: true,
      },
      {
        from: 'queryTypeInformational',
        to: 'finRoutedToTeam',
        weight: this.metrics.informationalAndFinRoutedToTeam,
        selected: this.args.selectedJob.sankey.highlightedLinks !== 'infoToRoutedToTeammate',
      },
      {
        from: 'queryTypeInformational',
        to: 'teammateOnly',
        weight: this.metrics.informationalAndTeammateOnly,
        selected: this.args.selectedJob.sankey.highlightedLinks !== 'infoToTeammate',
      },
      {
        from: 'queryTypePersonalized',
        to: 'finResolved',
        weight: this.metrics.personalizedAndFinResolved,
        selected: true,
      },
      {
        from: 'queryTypePersonalized',
        to: 'finRoutedToTeam',
        weight: this.metrics.personalizedAndFinRoutedToTeam,
        selected: this.args.selectedJob.sankey.highlightedLinks !== 'personalizedToRoutedToTeam',
      },
      {
        from: 'queryTypePersonalized',
        to: 'teammateOnly',
        weight: this.metrics.personalizedAndTeammateOnly,
        selected: true,
      },
      {
        from: 'queryTypeActions',
        to: 'finResolved',
        weight: this.metrics.actionsAndFinResolved,
        selected: true,
      },
      {
        from: 'queryTypeActions',
        to: 'finRoutedToTeam',
        weight: this.metrics.actionsAndFinRoutedToTeam,
        selected: this.args.selectedJob.sankey.highlightedLinks !== 'taskBasedToRoutedToTeam',
      },
      {
        from: 'queryTypeActions',
        to: 'teammateOnly',
        weight: this.metrics.actionsAndTeammateOnly,
        selected: true,
      },
      {
        from: 'queryTypeComplex',
        to: 'finResolved',
        weight: this.metrics.complexAndFinResolved,
        selected: true,
      },
      {
        from: 'queryTypeComplex',
        to: 'finRoutedToTeam',
        weight: this.metrics.complexAndFinRoutedToTeam,
        selected: true,
      },
      {
        from: 'queryTypeComplex',
        to: 'teammateOnly',
        weight: this.metrics.complexAndTeammateOnly,
        selected: true,
      },
    ];
  }

  get handlingToCsatNodes() {
    return [
      {
        from: 'finResolved',
        to: 'positiveCSAT',
        weight: this.metrics.finResolvedAndPositiveCSAT,
        selected: this.args.selectedJob.sankey.highlightedLinks !== 'resolvedByFinToPositiveCSAT',
      },
      {
        from: 'finResolved',
        to: 'neutralCSAT',
        weight: this.metrics.finResolvedAndNeutralCSAT,
        selected: true,
      },
      {
        from: 'finResolved',
        to: 'negativeCSAT',
        weight: this.metrics.finResolvedAndNegativeCSAT,
        selected: this.args.selectedJob.sankey.highlightedLinks !== 'resolvedByFinToNegativeCSAT',
      },
      {
        from: 'finRoutedToTeam',
        to: 'positiveCSAT',
        weight: this.metrics.finRoutedToTeamAndPositiveCSAT,
        selected: true,
      },
      {
        from: 'finRoutedToTeam',
        to: 'neutralCSAT',
        weight: this.metrics.finRoutedToTeamAndNeutralCSAT,
        selected: true,
      },
      {
        from: 'finRoutedToTeam',
        to: 'negativeCSAT',
        weight: this.metrics.finRoutedToTeamAndNegativeCSAT,
        selected: true,
      },
      {
        from: 'teammateOnly',
        to: 'positiveCSAT',
        weight: this.metrics.teammateOnlyAndPositiveCSAT,
        selected: true,
      },
      {
        from: 'teammateOnly',
        to: 'neutralCSAT',
        weight: this.metrics.teammateOnlyAndNeutralCSAT,
        selected: true,
      },
      {
        from: 'teammateOnly',
        to: 'negativeCSAT',
        weight: this.metrics.teammateOnlyAndNegativeCSAT,
        selected: true,
      },
    ];
  }

  nodeSelected(nodeId: string) {
    let highlightedLinks = this.args.selectedJob.sankey.highlightedLinks;
    let selectedNodes = FILTER_STATE_TO_SELECTED_NODES[highlightedLinks];
    return selectedNodes ? !selectedNodes.includes(nodeId) : false;
  }

  // TODO: localize these when the strings are finalized
  /* eslint-disable @intercom/intercom/no-bare-strings */
  get sankeySeries(): ExtendedSeriesSankeyOptions {
    let nodes =
      this.args.selectedJob.sankey.type === 'queryTypeToRes'
        ? [...this.queryTypeNodes, ...this.handlingNodes]
        : [...this.handlingNodes, ...this.csatNodes];
    let data =
      this.args.selectedJob.sankey.type === 'queryTypeToRes'
        ? this.queryTypeToHandlingNodes
        : this.handlingToCsatNodes;
    return {
      type: 'sankey',
      linkColorMode: 'gradient',
      animation: false,
      custom: {
        // this is used in the data label template (plotOptions.sankey.nodeFormat)
        total: this.metrics.conversations,
      },
      nodes,
      data,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Automation::AiInsights::FinInsights::Sankey': typeof Sankey;
    'reporting/automation/ai-insights/fin-insights/sankey': typeof Sankey;
  }
}
